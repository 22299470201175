<template>
    <div
        :class="currentLocale"
        :dir="currentLocale === 'language-ur'? 'rtl': 'ltr'">
        <slot/>
        <ShareModal v-if="$route.meta.store !== 'homepage'"/>
        <InputModal v-if="$route.meta.store !== 'homepage'"/>
        <MultiInputModal v-if="$route.meta.store !== 'homepage'"/>
        <ConfirmationModal v-if="$route.meta.store !== 'homepage'"/>
        <Alert v-if="$route.meta.store !== 'homepage'"/>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Header from '@/components/Header.vue';
    import mixins from '@/mixins';

    const Footer = () => import(/* webpackChunkName: "Footer" */ '@/components/Footer.vue');
    const AppBannerTwo = () => import(/* webpackChunkName: "AppBanner" */ '@/components/experiments/appbanner_v2/AppBanner.vue');
    const ShareModal = () => import(/* webpackChunkName: "Share" */ '@/components/Share.vue');
    const InputModal = () => import(/* webpackChunkName: "InputModal" */ '@/components/InputModal.vue');
    const MultiInputModal = () => import(/* webpackChunkName: "MultiInputModal" */ '@/components/MultiInputModal.vue');
    const ConfirmationModal = () => import(/* webpackChunkName: "ConfirmationModal" */ '@/components/ConfirmationModal.vue');
    const Alert = () => import(/* webpackChunkName: "Alert" */ '@/components/Alert.vue');
    const AppBannerTop = () => import(/* webpackChunkName: "AppBannerTop" */ '@/components/AppBannerTop.vue');

    export default {
        components: {
            Header,
            Footer,
            AppBannerTwo,
            ShareModal,
            InputModal,
            MultiInputModal,
            ConfirmationModal,
            Alert,
            AppBannerTop
        },
        mixins: [
            mixins
        ],
        data () {
            return {
                currentLocale: '',
                showAppBanner: this.getCookie('appBannerTop_viewed') == null || this.getCookie('appBannerTop_viewed') === 'true',
                showTermsMsg: this.getCookie('terms_update_notified') == null || this.getCookie('terms_update_notified') === 'true'
            };
        },
        computed: {
            ...mapGetters([
                'getUserDetails',
                'getNotificationCount',
                'messageNotificationList'
            ])
        },
        watch: {
            'getUserDetails.userId' (newValue) {
                if (newValue) {
                    this.fetchInitialNotifications({ language: this.getCurrentLanguage().fullName.toUpperCase(), resultCount: 10 });
                }
            }
        },
        created () {
            this.currentLocale = 'language-' + 'en';
        },
        methods: {
            ...mapActions([
                'fetchUserDetails',
                'setAfterLoginAction',
                'fetchInitialNotifications',
                'attachMessageNotificationListener'
            ])
        }
    };
</script>

<style lang="scss">
.modal-body {
    max-height: calc(100vh - 140px);
    overflow-y: auto;
}
.page-wrap {
    margin-bottom: 50px;
}
</style>
